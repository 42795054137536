import {
  BREAKPOINTS,
  GlobalNavigationBar,
  MenuNavigationSection,
  MobileNavigationMenuProps,
  ProfileMenu,
  SideMenu,
  Size,
  SystemIcons,
} from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFeatures } from '../../../hooks/Features';
import { selectGigyaAccount, selectIsAuthenticated, selectUserProfile } from '../../../store/account/accountSlice';
import { useEffect, useState } from 'react';
import { DesktopNavigationMenuProps } from '@laerdal/life-react-components/dist/GlobalNavigationBar/types';
import { PermissionsType, usePermissions } from "../../../hooks/Permissions";
import { useNavigate } from 'react-router-dom';
import { useMediaMatch } from 'rooks';
import { ProfileComponent } from "@laerdal/navigation"
import Cookie from 'universal-cookie';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #00000080;
  display: flex;
  justify-content: right;
`;

const IFrame = styled.iframe`
  width: 100%;
`

const SideContainer = styled(SideMenu)`
  & > div:first-child {
    margin-left: 16px;
  }

  & > div {
    padding: 0;
  }
`

const Header = () => {
  const { t, i18n } = useTranslation('Header');
  const navigate = useNavigate();
  const isSmallScreen = useMediaMatch(BREAKPOINTS.SMALL.replace('@media ', ''));

  const { hasPermissions } = usePermissions();
  const hasAccessToManageAccess = hasPermissions(PermissionsType.ManageAccess);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const account = useSelector(selectGigyaAccount);
  const user = useSelector(selectUserProfile);

  const [showHelpU, setShowHelpU] = useState<boolean>(false);
  const [desktopMenu, setDesktopMenu] = useState<DesktopNavigationMenuProps>();
  const [mobileMenu, setMobileMenu] = useState<MobileNavigationMenuProps>();
  

  const buildNavigationMenu = (isMobile: boolean) => {
    const navigationItems = {
      items: [{
        label: t('Cases'),
        to: '/cases',
        action: (e: React.MouseEvent) => {
            //@ts-ignore
            posthog.capture?.('NavigationTab Click',{
              tab_name: 'Cases',
            })
          }
        }
      ],
    } as any;


    if (isAuthenticated) {      
      if (!!user?.currentOrganization){
        navigationItems.items.push({
            label: t('Overview'),
            to: '/overview',
            exact: true,
            action: (e: React.MouseEvent) => {
              //@ts-ignore
              posthog.capture?.('NavigationTab Click',{
                tab_name: 'Overview',
              })
            }
          },
        );
      }
      
      if (!!user?.currentOrganization && user.currentOrganization?.allowOrders){
        navigationItems.items.push({
          label: t('Orders & Invoices'),
          to: '/ordersinvoices',
          action: (e: React.MouseEvent) => {
            //@ts-ignore
            posthog.capture?.('NavigationTab Click',{
              tab_name: 'Orders',
            })
          }

        });
      }
      
      if (hasPermissions(PermissionsType.ViewAppointments)) {
        navigationItems.items.push({
          label: t('Appointments'),
          to: '/appointments',
          action: (e: React.MouseEvent) => {
            //@ts-ignore
            posthog.capture?.('NavigationTab Click',{
              tab_name: 'Appointments',
          })}
        });
      }

      if (user?.currentOrganization?.isFederatedSsoEnabled) {
        navigationItems.items.push({
          label: t('SAML'),
          to: '/saml',
          action: (e: React.MouseEvent) => {
            //@ts-ignore
            posthog.capture?.('NavigationTab Click',{
              tab_name: 'SAML',
            })}
        });
      }

      navigationItems.buttons = [
        {
          type: 'profile'
        },
        {
          type: 'switcher',
          action: () => {
            window.globalNav.toggle();
          },
        },
      ];

      if(process.env.REACT_APP_HELPU?.toString() == 'true')
      {
        navigationItems.buttons.unshift(
        {
          type: 'action',
          action: () => {
            //@ts-ignore
            window.renderHelpUComponent({
              product: 'LConnect',
              source: 'salesforce',
              locale: "en",
              title: 'Account help'
            })
          },
          icon: <SystemIcons.Help />,
        });
      }

      if(isSmallScreen){
        navigationItems.action = {
          label: t('Dashboard'),
          action: () => {
            window.location.href = process.env.REACT_APP_CONNECT_URL ?? ''
          },
        }
      }
    }

    return navigationItems;
  };

  useEffect(() => {

    setDesktopMenu(buildNavigationMenu(false));
    setMobileMenu(buildNavigationMenu(true));
  }, [isAuthenticated,  account, user, i18n.language, isSmallScreen]);


  return (
    <>
      <GlobalNavigationBar
        name={t('Account')}
        logo={{
          to: '/'
        }}
        isAuthenticated={isAuthenticated}
        useMaxWidth={true}
        desktop={desktopMenu}
        mobile={mobileMenu}
        profileComponent={
          <ProfileComponent
            organizationName={user?.currentOrganization?.name}
            user={{
              firstName: user?.firstName ?? "",
              lastName: user?.lastName ?? "",
              email: user?.email ?? "",
              market: user?.currentOrganization?.services?.find(x=>x.name == "Webshop")?.commerceMarket?.code
            }}
            
            customContent={
              user?.currentOrganization && hasAccessToManageAccess
              ? {
                  title: t('Account'),
                  items: [
                    {
                      icon: <SystemIcons.AddUser/>,
                      label: t('Add people'),
                      onClick: () => {              
                        //@ts-ignore
                        posthog.capture?.('NavigationUserProfile AddPeople')
                        navigate('users/1');
                      },
                    },
                  ],
                }
              : undefined
            }
            //@ts-ignore
            env={process.env.REACT_APP_CONNECT_ENV ?? "dev"}
            locale={i18n.language}
            onSignOut={() => { 
              const cookie = new Cookie();
              // @ts-ignore
              cookie.remove('laerdal-signin-on-behalf', {path: "/", domain: ".laerdal.com"});
              
              // @ts-ignore
              window.gigya.accounts.logout(); 
            }}
            hideLaguage={false}
          />
        }
      />
    </>
  );
};

export default Header;